<template>
  <div class="mox">
    <div>
      <div class="msg clear">
        <div :class="[source.userId == userId ? 'fr' : 'fl']">
          <div class="user-head" v-if="source.userHeadUrl">
            <img :src="source.userHeadUrl" alt="" />
          </div>
          <div class="user-head" v-else>
            <img src="@/assets/user.png" alt="" />
          </div>
        </div>
        <div class="user-msg" :class="[source.userId == userId ? 'fr' : 'fl']">
          <span :style="source.userId == userId ? 'float: right' : ''">{{
            source.nickName ? source.nickName : "系统管理员"
          }}</span>
        </div>
      </div>
      <div class="clear">
        <div :class="source.userId == userId ? 'right bg' : 'left'">
          {{ source.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ListComponent',
  props: {
    source: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(['userId'])
  }
};
</script>
<style lang="less" scoped>
.mox {
  margin-bottom: 20px;
}
.msg {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  .user-head {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user-msg {
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    color: #888888;
    font-size: 14px;
  }
}
.left,
.right {
  display: inline-block;
  background: #ffffff;
  color: #888888;
  max-width: 211px;
  min-width: 20px;
  width: auto;
  margin: 5px 0 10px 0;
  padding: 12px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 5px;
  word-wrap: break-word;
}
.left {
  animation: toLeft 0.5s ease both 1;
  margin-left: 40px;
}
.right {
  animation: toright 0.5s ease both 1;
  float: right;
  margin-right: 40px;
}
.bg {
  background: #00bd92;
  color: #fff;
}
@keyframes toLeft {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes toright {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>